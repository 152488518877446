<template>
    <div class="card mb-0 border">
        <div class="card-header">
            <h3 class="card-title text-dark">Update Password</h3>
        </div>
        <div class="card-body">
            <div class="mb-4">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Current Password</label>
                            <input v-model="user_.curr_password" :class="[v$.curr_password.$error ? 'border-danger': '']" type="password" name="curr_password" class="form-control" placeholder="Enter Current Passowrd">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">New Password</label>
                            <input v-model="user_.password" :class="[v$.password.$error ? 'border-danger': '']" type="password" class="form-control" name="password" placeholder="Enter New Passowrd">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-label">Confirm Password</label>
                            <input v-model="user_.confirmPass" :class="[v$.confirmPass.$error ? 'border-danger': '']" type="password" class="form-control" name="confirmPass" placeholder="Confirm New Passowrd">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-4">
                    <button type="button" class="btn default-button" v-on:click.prevent="updatePassword">Update</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { reactive } from 'vue';
    import axios from 'axios'
    import useVuelidate from '@vuelidate/core'
    import { required } from '@vuelidate/validators'
    import { useToast } from "vue-toastification"

    const toast = useToast();

    // Add form validation
    const user_ = reactive({
        curr_password:'',
        password:'',
        confirmPass:'',
    })

    const rules = {
        curr_password:{ required },
        password:{ required },
        confirmPass:{ required }
    }
    const v$ = useVuelidate(rules, user_)

    function updatePassword(){
        v$.value.$touch()
        if (!v$.value.$error) {
            console.log(user_)
            axios.post('update_password',user_).then(function (response) {
                if (response.data.status == 'success') {
                    toast.success(response.data.message);
                    user_.curr_password = ''
                    user_.password = ''
                    user_.confirmPass = ''
                    v$.value.$reset();
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        toast.error(error.response.data.message);
                    }else if (error.response.status == 401) {
                        toast.error(error.response.data.message);
                    }
                }
            });
        }
    }
</script>