<template>
    <AppContainer>
        <Settings/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/UserDashboardContainer.vue'
import Settings from '@/components/userDashboard/settings/Settings.vue'
export default {
    name: "UserSettings",
    components: {
        AppContainer,
        Settings
    }
}
</script>